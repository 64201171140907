<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="标题">
                <el-input v-model="search.title" size="small"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading ="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column show-overflow-tooltip width="350" prop="title" label="标题" fixed/>
            <el-table-column show-overflow-tooltip prop="modular" label="模块"/>
            <el-table-column show-overflow-tooltip width="180" prop="remindTargetDate" label="到期时间"/>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="showAnnouncement(row)">处理</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <component :is="remind.componentName" :remind="remind" @submitted="refresh">
        </component>
    </div>
</template>
<script>
    import * as service from "@/service/system/remind";
    import Vue from "vue";
    import XTableBase from "@/components/x/XTableBase";
    export default {
        name: "RemindList",
        mixins: [XTableBase],
        data() {
            this.refreshService = service.remindList;
            return {
                search: {
                    title: '',
                },
                tableData: [],
                count: 0,
                comp: "div",
                remind: {
                    componentName: "div",
                    id: null,
                    visible: false,
                },
                fzjType: {
                    type: '1',
                    modelId: ''
                }
            }
        },
        methods: {
            showAnnouncement(record) {
                this.remind.id = record.id;
                this.remind.visible = true;
                Vue.component(record.view, (resolve) => require([`@/view/remind/${record.view}`], resolve));
                this.remind.componentName = record.view;

            }
        }
    }
</script>

<style scoped>
</style>
